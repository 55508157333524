export const PaymentJson = [
  {
    id: 4,
    name: "Pay by Credit / Debite Card",
    img: require("../../Assets/images/Payment/card.png"),
    width: "200px",
    showPayBtn: false,
    openModal: true,
  },
  {
    id: 5,
    name: "Pay by Paymob",
    img: require("../../Assets/images/Payment/card.png"),
    width: "200px",
    showPayBtn: false,
    openModal: true,
    payMobMethod: true,
  },
  {
    id: 7,
    name: "الدفع بواسطة بطاقة الخصم المباشر - البطاقة الإئتمانية",
    img: require("../../Assets/images/Payment/MPGs.png"),
    width: "200px",
    showPayBtn: false,
    openModal: false,
    payMobMethod: false,
  },
  {
    id: 8,
    name: "الدفع بواسطة ميزة والمحافظ الإلكترونية",
    img: require("../../Assets/images/Payment/UPG.png"),
    width: "200px",
    showPayBtn: false,
    openModal: false,
    payMobMethod: false,
  },
  {
    id: 6,
    name: "Pay by Paymob (Mobile Wallets)",
    img: require("../../Assets/images/Payment/paymobmobile.png"),
    width: "250px",
    showPayBtn: false,
    openModal: true,
    payMobMethod: false,

  },

  {
    id: 1,
    name: "الدفع من محفظة نقاط أمواج",
    img: require("../../Assets/images/Payment/wallet.png"),
    width: "100px",
    showPayBtn: true,
    openModal: false,
  },
];
