import { toast } from "react-toastify";
import { Pay } from "../../Api/Api";
import { Axios } from "../../Api/axios";
import Loading from "../../Components/Loading/Loading";
import { useEffect , useContext , useState} from "react";
import { Ticket } from "../../Context/TicketContext";

import { useNavigate } from "react-router-dom";
import { trackEvent } from '../../utils/facebookPixel';

export default function PaymentMobileRedirect() {
  const search = new URLSearchParams(window.location.search);
  const GetTicket = useContext(Ticket);
  const [tickets, setTickts] = useState([]);
  const amount = GetTicket.ticket.price;
  const handlePurchase = () => {
    trackEvent('Purchase', { value: amount, currency: 'EGP' });
};
  const nav = useNavigate();
  useEffect(() => {
    const transaction_id = search.get("id");
    const transaction_status = search.get("success");
    //const tickets = localStorage.getItem("tickets");
    if(transaction_status === "true"){handlePurchase()};
    transaction_status === "true" ? toast.success("تمت عملية الدفع بنجاح") : toast.error("فشلت عملية الدفع");

    nav("/profile");
    async function send() {
      const res = await Axios.post(`/${Pay}`, {
        payment_method: 6,
        tickets: tickets,
        transaction_id: transaction_id,
      }).finally(() => {
      	if(transaction_status === "true"){handlePurchase()};
        toast.success(
          transaction_status === "true"
            ? "تمت عملية الدفع بنجاح"
            : "فشلت عملية الدفع"
        );
        nav("/profile");
      });
    }

    send();
  }, []);

  return <Loading />;
}
