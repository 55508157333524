import React, { useState } from "react";

import { Button, Container, Form } from "react-bootstrap";
import SecondHeader from "../../Components/Header/SecondHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeCircleCheck,
  faIdCard,
  faPhoneFlip,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import "./register.css";
import { Axios } from "../../Api/axios";
import {
  CheckEmail,
  CheckMobile,
  CheckNational,
  OTP,
  REGISTER,
} from "../../Api/Api";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

import Cookie from "cookie-universal";

export default function Register() {
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    login_email: "",
    login_mobile: "",
    password: "",
    repassword: "",
    type: "أختر النوع",
    id_type: "ID Type",
    id_number: "",
    country_id: "1",
    job: "",
    otp: "",
  });
  const [err, setErr] = useState({
    mobile: "",
    email: "",
    idNumber: "",
  });
  const [sent, setSent] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  // Navigate
  const nav = useNavigate();
  // Loading
  const [loading, setLoading] = useState(false);

  const cookie = Cookie();

  function handleChange(e) {
    setForm({
      ...form,
      [e.target.name]:
        e.target.name === "otp" ? Number(e.target.value) : e.target.value,
    });
  }

  // Function to handle key press event
	// Add state variables for checkbox state and terms and conditions link
const [termsAccepted, setTermsAccepted] = useState(false);
const [canSubmit, setCanSubmit] = useState(false);

// Function to toggle checkbox state
const handleCheckboxChange = () => {
  setTermsAccepted(!termsAccepted);
  setCanSubmit(!canSubmit);

};

// Function to navigate to terms and conditions page
const navigateToTerms = () => {
  // Replace '/terms' with the actual URL of your terms and conditions page
  window.location.href = '/terms';
};

  const handleKeyPress = (event) => {
    const arabicRegex = /[\u0600-\u06FF\s]/;

    if (!arabicRegex.test(event.key)) {
      event.preventDefault();
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    let flag = true;
    try {
      const res = await Axios.post(`/${CheckMobile}`, {
        mobile: form.login_mobile,
      });
      const res2 = await Axios.post(`/${CheckEmail}`, {
        email: form.login_email,
      });
      let res3 = "success";
      if (form.id_number !== "") {
        res3 = await Axios.post(`/${CheckNational}`, {
          nid: form.id_number,
        });
      }

      if (
        res.data.status === "fail" ||
        res2.data.status === "fail" ||
        res3?.data?.status === "fail"
      ) {
        flag = false;
        setErr({
          mobile: res.data.message,
          email: res2.data.message,
          idNumber: res3.data.message,
        });
      }
      if (flag) {
        setShowOTP(true);
        await Axios.post(`/${OTP}`, {
          mobile: Number(form.login_mobile),
        });
      }
      setSent(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
  const [disabled, setDisabled] = useState(false);
  async function repeatOtp() {
    setDisabled(true);
    await Axios.post(`/${OTP}`, {
      mobile: Number(form.login_mobile),
    });

    setTimeout(() => {
      setDisabled(false);
    }, 60000);
  }

  async function Register(e) {
    e.preventDefault();
    const res = await Axios.post(`/${REGISTER}`, form);
    const accessToken = res.data.access_token;
    const refreshToken = res.data.refresh_token;

    setLoading(false);

    if (res.data.response === 200) {
      cookie.set("amawaj-pri", accessToken);
      cookie.set("amawaj-pri-rtl", refreshToken);
      nav("/");
    }
  }

  return (
    <>
      <SecondHeader
        title="سجّل"
        secondTitle="مرحبا قم _ بإنشاء حساب جديد"
        none="d-none"
      />
      <div className="bg-url">
        <Container>
          <div
            dir="rtl"
            className="vh-md-100 d-flex justify-content-center align-items-center py-5"
          >
            {!showOTP ? (
              <Form className="col-lg-10 col-12" onSubmit={handleSubmit}>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <Form.Group
                    className="mb-3 col-md-5 col-12"
                    controlId="formBasicFirstName"
                  >
                    <Form.Label className="fs-2 gold">الأسم الأول</Form.Label>
                    <div className="position-relative">
                      <img
                        src={require("../../Assets/images/icon/user.png")}
                        alt={"icon"}
                        width={"25px"}
                        className={`position-absolute end-0 translate-middle ${
                          sent ? "top-35" : "top-50"
                        }`}
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.first_name === ""
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        type="text"
                        placeholder="الأسم الأول"
                        onKeyPress={handleKeyPress}
                        onInput={handleKeyPress}
                        onChange={handleChange}
                        name="first_name"
                        value={form.first_name}
                      />
                      {sent &&
                        (form.first_name === "" ? (
                          <p className="text-danger mb-0">أدخل الأسم الأول</p>
                        ) : (
                          sent &&
                          form.first_name !== "" && (
                            <p className="text-success mb-0">محقق</p>
                          )
                        ))}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-md-5 col-12"
                    controlId="formBasicLastName"
                  >
                    <Form.Label className="fs-2 gold">الأسم الأخير</Form.Label>
                    <div className="position-relative">
                      <img
                        src={require("../../Assets/images/icon/user.png")}
                        alt={"icon"}
                        width={"25px"}
                        className={`position-absolute end-0 translate-middle ${
                          sent ? "top-35" : "top-50"
                        }`}
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.first_name === ""
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        onKeyPress={handleKeyPress}
                        onInput={handleKeyPress}
                        type="text"
                        placeholder="الأسم الأخير"
                        onChange={handleChange}
                        name="last_name"
                        value={form.last_name}
                      />
                      {sent &&
                        (form.last_name === "" ? (
                          <p className="text-danger mb-0">أدخل الأسم الأخير</p>
                        ) : (
                          sent &&
                          form.last_name !== "" && (
                            <p className="text-success mb-0">محقق</p>
                          )
                        ))}
                    </div>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <Form.Group
                    className="mb-3 col-md-8 col-12"
                    controlId="formBasicNumberPhone"
                  >
                    <Form.Label className="fs-2 gold">رقم التليفون</Form.Label>
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faPhoneFlip}
                        className="position-absolute end-0 top-35 translate-middle"
                        fontSize={25}
                      />
                      <Form.Control
                        minLength={11}
                        maxLength={11}
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.login_mobile === "" ||
                          err.mobile === "رقم الموبايل يجب أن يكون 11 رقم"
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        type="number"
                        placeholder="رقم التليفون"
                        onChange={handleChange}
                        name="login_mobile"
                        value={form.login_mobile}
                      />
                      {sent &&
                        (form.login_mobile === "" ? (
                          <p style={{ color: "red" }}>
                            أدخل رقم الهاتف الخاص بك
                          </p>
                        ) : sent &&
                          err.mobile === "رقم الموبايل يجب أن يكون 11 رقم" ? (
                          <p style={{ color: "red" }}>
                            رقم الموبايل يجب أن يكون 11 رقم
                          </p>
                        ) : (
                          <p className="text-success mb-0">محقق</p>
                        ))}
                    </div>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <Form.Group
                    className="mb-3 col-md-8 col-12"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="fs-2 gold">
                      عنوان البريد الألكتروني
                    </Form.Label>
                    <div className="position-relative">
                      <img
                        src={require("../../Assets/images/icon/email.png")}
                        alt={"icon"}
                        width={"25px"}
                        className="position-absolute end-0 top-35 translate-middle"
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.email === "" ||
                          err.email === "Email is already registered"
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        type="email"
                        placeholder="اكتب البريد الألكتروني الخاص بك"
                        onChange={handleChange}
                        name="login_email"
                        value={form.login_email}
                      />
                      {sent &&
                        (form.email === "" ? (
                          <p style={{ color: "red" }}>
                            الرجاء أدخل البريد الألكتروني الخاص بك
                          </p>
                        ) : err.email === "Email is already registered" ? (
                          <p style={{ color: "red" }}>
                            البريد الألكتروني مستخدم سابقا
                          </p>
                        ) : (
                          <p className="text-success mb-0">محقق</p>
                        ))}
                    </div>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <Form.Group
                    className="mb-3 col-md-5 col-12"
                    controlId="formBasicPassword"
                  >
                    <Form.Label className="fs-2 gold">كلمة المرور</Form.Label>
                    <div className="position-relative">
                      <img
                        src={require("../../Assets/images/icon/padlock.png")}
                        alt={"icon"}
                        width={"25px"}
                        className={`position-absolute end-0 translate-middle ${
                          sent ? "top-35" : "top-50"
                        }`}
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.password === ""
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        type="password"
                        placeholder="كلمة المرور"
                        onChange={handleChange}
                        name="password"
                        value={form.password}
                      />
                      {sent && form.password === "" ? (
                        <p className="text-danger mb-0">
                          الرجاء أدخل كلمة المرور
                        </p>
                      ) : (
                        sent &&
                        form.password !== "" && (
                          <p className="text-success mb-0">محقق</p>
                        )
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-md-5 col-12"
                    controlId="formBasicRePassword"
                  >
                    <Form.Label className="fs-2 gold">
                      أعد كتابة كلمة المرور
                    </Form.Label>
                    <div className="position-relative">
                      <img
                        src={require("../../Assets/images/icon/padlock.png")}
                        alt={"icon"}
                        width={"25px"}
                        className={`position-absolute end-0 translate-middle ${
                          sent ? "top-35" : "top-50"
                        }`}
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.repassword === "" ||
                          form.repassword !== form.password
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        type="password"
                        placeholder="اكتب كلمة المرور الخاصة بك"
                        onChange={handleChange}
                        name="repassword"
                        value={form.repassword}
                      />
                      {sent &&
                        (form.repassword === "" ? (
                          <p className="text-danger mb-0">
                            الرجاء أعد كتابة كلمة المرور
                          </p>
                        ) : sent && form.repassword !== form.password ? (
                          <p className="text-danger mb-0">
                            كلمة المرور غير متطابقة
                          </p>
                        ) : (
                          <p className="text-success mb-0">محقق</p>
                        ))}
                    </div>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <Form.Group
                    className="mb-3 col-md-8 col-12"
                    controlId="formBasicType"
                  >
                    <Form.Label className="fs-2 gold">الجنس</Form.Label>
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faVenusMars}
                        className={`position-absolute end-0 translate-middle ${
                          sent ? "top-35" : "top-50"
                        }`}
                        fontSize={22}
                      />
                      <Form.Select
                        aria-label="Default select example"
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.type === "أختر الجنس"
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        onChange={handleChange}
                        name="type"
                        value={form.type}
                      >
                        <option disabled>أختر الجنس</option>
                        <option value="1">ذكر</option>
                        <option value="2">انثى</option>
                      </Form.Select>
                      {sent &&
                        (form.type === "أختر الجنس" ? (
                          <p className="text-danger mb-0">الرجاء إدخال الجنس</p>
                        ) : (
                          <p className="text-success mb-0">محقق</p>
                        ))}
                    </div>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <Form.Group
                    className="mb-3 col-md-5 col-12"
                    controlId="formBasicTypeNumber"
                  >
                    <Form.Label className="fs-2 gold">نوع ال ID</Form.Label>
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faVenusMars}
                        className={`position-absolute end-0 translate-middle top-50
                       ${
                         form.id_number !== ""
                           ? "border-3 border-danger"
                           : form.id_number === ""
                           ? ""
                           : "border-3 border-success"
                       } `}
                        fontSize={22}
                      />
                      <Form.Select
                        aria-label="Default select example"
                        className={`pe-5 py-3 fs-4 `}
                        onChange={handleChange}
                        name="id_type"
                        value={form.id_type}
                      >
                        <option disabled>ID Type</option>
                        <option value="1">National </option>
                        <option value="2">PASSPORT</option>
                      </Form.Select>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 col-md-5 col-12"
                    controlId="formBasicIdNumber"
                  >
                    <Form.Label className="fs-2 gold">رقم ال ID </Form.Label>
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faIdCard}
                        className={`position-absolute end-0 translate-middle ${
                          sent ? "top-35" : "top-50"
                        }`}
                        fontSize={25}
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (err.idNumber === "National ID is already registered"
                            ? "border-3 border-danger"
                            : form.id_number === ""
                            ? ""
                            : "border-3 border-success")
                        }`}
                        type="number"
                        placeholder="اكتب الرقم"
                        onChange={handleChange}
                        name="id_number"
                        value={form.id_number}
                      />

                      {sent &&
                        (err.idNumber ===
                        "National ID is already registered" ? (
                          <p style={{ color: "red" }}>
                            الرقم المدخل مستخدم سابقا مستخدم سابقا
                          </p>
                        ) : form.id_number === "" ? (
                          ""
                        ) : (
                          <p className="text-success mb-0">محقق</p>
                        ))}
                    </div>
                  </Form.Group>
                </div>
                {/* <div className="d-flex align-items-center justify-content-center">
                  <Form.Group
                    className="mb-3 col-md-8 col-12"
                    controlId="formBasicJop"
                  >
                    <Form.Label className="fs-2 gold">العمل </Form.Label>
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faBriefcase}
                        className={`position-absolute end-0 translate-middle ${
                          sent ? "top-35" : "top-50"
                        }`}
                        fontSize={25}
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.job === ""
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        type="text"
                        placeholder="اكتب العمل الخاص بك"
                        onChange={handleChange}
                        name="job"
                        value={form.job}
                      />
                      {sent &&
                        (form.job === "" ? (
                          <p className="text-danger mb-0">
                            الرجاء إدخال العمل الخاص بك
                          </p>
                        ) : (
                          <p className="text-success mb-0">محقق</p>
                        ))}
                    </div>
                  </Form.Group>
                </div> */}
				  {/* Checkbox for accepting terms and conditions */}
  <Form.Group className="fs-2 gold" controlId="formBasicCheckbox">
    <Form.Check
      type="checkbox"
      label="أوافق على الشروط والأحكام"
      onChange={handleCheckboxChange}
      checked={termsAccepted}
    />
  </Form.Group>

  {/* Link to terms and conditions */}
  <div className="fs-2 gold" style={{color:'#000',fontSize: '16px',}}>
    <span className="link" onClick={navigateToTerms}>
    إستمرارك يعني موافقتك على الشروط والأحكام {" "} إضغط هنا لقراءة
      الشروط والأحكام
    </span>
  </div>

                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    className="bg-gold text-white mt-5 col-8 py-1 border-0 fs-1 position-relative"
                    type="submit"
                    disabled={!canSubmit}
                    style={{ height: loading && "70px" }}
                  >
                    {!loading && "تسجيل حساب"}
                    {loading && (
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <Loading />
                      </div>
                    )}
                  </Button>
                </div>
              </Form>
            ) : (
              <Form className="w-100" onSubmit={Register}>
                <div className="d-flex align-items-center justify-content-center">
                  <Form.Group
                    className="mb-3 col-12"
                    controlId="formBasicNumberPhone"
                  >
                    <Form.Label className="fs-2 gold">
                      ادخل رمز التحقق
                    </Form.Label>
                    <div className="position-relative">
                      <FontAwesomeIcon
                        icon={faEnvelopeCircleCheck}
                        fontSize={20}
                        className="position-absolute end-0 top-50 translate-middle"
                      />
                      <Form.Control
                        className={`pe-5 py-3 fs-4 ${
                          sent &&
                          (form.otp === ""
                            ? "border-3 border-danger"
                            : "border-3 border-success")
                        }`}
                        type="number"
                        placeholder="ادخل رمز التحقق"
                        onChange={handleChange}
                        name="otp"
                        value={form.otp}
                      />
                    </div>
                  </Form.Group>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <Button
                    className="bg-gold text-white mt-5 col-4 py-1 border-0 fs-3 position-relative"
                    type="submit"
                    disabled={loading}
                    style={{ height: loading && "70px" }}
                  >
                    {!loading && "ارسل الرمز"}
                    {loading && (
                      <div className="position-absolute top-50 start-50 translate-middle">
                        <Loading />
                      </div>
                    )}
                  </Button>
                  <Button
                    className="bg-custom-gray text-white text-center rounded mt-5 col-4 py-1 border-0 fs-3 position-relative"
                    type="button"
                    disabled={disabled}
                    onClick={repeatOtp}
                  >
                    اعادة ارسال رمز التحقق
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}
