import { useEffect , useContext , useState } from "react";
import { Axios } from "../../Api/axios";
import { Pay } from "../../Api/Api";

import { useNavigate } from "react-router-dom";
import Check from "../../Components/Check/Check";
import { toast } from "react-toastify";
import { trackEvent } from '../../utils/facebookPixel';
import { Ticket } from "../../Context/TicketContext";

export default function PaymentRedirect() {
  const search = new URLSearchParams(window.location.search);
  const GetTicket = useContext(Ticket);
  const amount = GetTicket.ticket.price;
  const [tickets, setTickts] = useState([]);

  const handlePurchase = () => {
    trackEvent('Purchase', { value: amount, currency: 'EGP' });
};

  const nav = useNavigate();

  useEffect(() => {
    const transaction_id = search.get("transaction_id");
    const transaction_status = search.get("transaction_status");
    //const tickets = localStorage.getItem("tickets");
    async function send() {
      const res = await Axios.post(`/${Pay}`, {
        payment_method: 5,
        tickets: tickets,
        transaction_id: transaction_id,
      }).finally(() => {
      	if(transaction_status === "success"){handlePurchase()};
      	if(transaction_status === "success"){toast.success("تمت عملية الدفع بنجاح")};
        
        nav("/profile");
      });
    }

    send();
  }, []);
  return <Check />;
}
